import React, { useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useStore } from "@common/website/state/root_store";

import { Container } from "@common/website/components/shared/layout";
import Video from "@common/website/components/shared/video/video";
import Header from "./lib/header";
import Card from "./lib/card";
import Section from "./lib/section";

import kjopHorizontal from "@common/website/assets/images/kjop_horizontal.png";
import kjopHorizontal2x from "@common/website/assets/images/kjop_horizontal@2x.png";
import nyhetBanner from "@common/website/assets/images/nyhet_banner.png";
import nyhetBanner2x from "@common/website/assets/images/nyhet_banner@2x.png";
import nyhetBannerDark from "@common/website/assets/images/nyhet_banner_dark.png";
import nyhetBannerDark2x from "@common/website/assets/images/nyhet_banner_dark@2x.png";

// Ny rekkefølge

const Home = () => {
	const store = useStore();
	return (
		<PageContainer>
			<Card
				href="https://privatmegleren.no/settprispå"
				type="settprispaa"
				title="Sett pris på"
				leade="Vi har videreutviklet tjenesten som gjør det enkelt å sette pris på deg selv og andre. Estimer verdien på din egen bolig og de du er nysgjerrig på."
			/>
			<Header />
			<Buttons>
				<Button href="/kontorer/inarheten">Finn en lokal megler</Button>
				<Button href="/kjope-bolig">Våre eiendommer</Button>
			</Buttons>
			<Card
				href="https://notis.privatmegleren.no"
				type="notis"
			/>
			<Card href="https://privatmegleren.no/kjop" type="kjop" opacity="1" />
			<Card href="https://privatmegleren.no/boligbytte" type="boligbytte" opacity="1" />
			<ContentHeading style={{ float: "left", textAlign: "center", width: "100%" }}>
				Tre viktigste årsaker til
				<br />
				at kunder velger oss
			</ContentHeading>
			<Cards as="section">
				<Card
					href="https://privatmegleren.no/kontorer"
					type="vare_meglere"
					number="1"
					title="Våre meglere"
					leade="Håndplukkede eiendomsmeglere i ditt nærmiljø"
					special={true}
				/>
				<Card
					href="https://privatmegleren.no/klikk"
					type="klikk"
					number="2"
					title="VÅR MARKEDSFØRING AV BOLIG"
					leade=""
				/>
				<Card
					href="https://privatmegleren.no/mittboligsalg"
					type="mitt_boligsalg"
					number="3"
					title="Mitt boligsalg"
					leade="Du kan følge boligsalget ditt i løsningen Mitt Boligsalg"
					special={true}
				/>
				<Card
					href="https://privatmegleren.no/verdivurdering"
					type="verdivurdering"
					title="Hva er din bolig verdt?"
					leade="La en av våre meglere bistå med salg av din bolig"
				/>
			</Cards>
			<SectionVideo
				title="Eventvisning"
				leade="Øk sjansene for salg ved å holde en annerledes visning som retter fokus mot det unike ved din bolig."
			>
				<Video />
			</SectionVideo>
		</PageContainer>
	);
};

const PageContainer = styled(Container)`
	position: relative;
	max-width: 100%;
	padding-top: 72px;

	${({ theme }) => theme.mediaQueries.desktop`
		padding-top: 72px;
	`};
`;

const NyhetBanner = styled.picture`
	position: absolute;
	top: 72px;
	left: -12px;
	height: 72px;
	width: auto;
	z-index: 20;
	overflow: hidden;

	${({ theme }) => theme.mediaQueries.tablet`
		left: 0;
	`};

	${({ theme }) => theme.mediaQueries.desktop`
		left: 0;
	`};
`;

const KjopBar = styled(Link)`
	position: absolute;
	top: 72px;
	width: 100%;
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	background: #222626;
	color: #fff;
	text-decoration: none;
	z-index: 10;

	p {
		margin: 4px 0 0 0;
		line-height: 14px;
		font-size: 12px;
	}

	${({ theme }) => theme.mediaQueries.tablet`
		background: #191c1c;
		p {
			font-size: 14px;
		}
	`};

	${({ theme }) => theme.mediaQueries.desktop`
		background: #191c1c;
		p {
			font-size: 14px;
		}
	`};
`;

const KjopHorizontal = styled.img`
	height: 14px;
	width: auto;

	${({ theme }) => theme.mediaQueries.tablet`
		height: 16px;
	`};

	${({ theme }) => theme.mediaQueries.desktop`
		height: 16px;
	`};
`;

const Buttons = styled(Container)`
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	padding: 24px 12px 24px 12px;

	${({ theme }) => theme.mediaQueries.desktop`
		max-width: 448px;
		margin: 50px auto 50px auto;
	`};
`;

const Button = styled.a`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 42px;
	width: calc(50% - 6px);
	background: #fff;
	color: #000;
	font-weight: 400;
	text-decoration: none;
`;

const ContentHeading = styled.h3`
	position: relative;
	display: block;
	text-align: center;
	margin: 54px 0 54px 0;
	font-size: 30px;
	line-height: 1.333;

	&:after {
		content: "";
		position: absolute;
		bottom: -18px;
		left: 50%;
		width: 72px;
		height: 2px;
		transform: translate3d(-50%, 0, 0);
		background: ${({ theme }) => theme.colors.gold.primary};
	}

	${({ theme }) => theme.mediaQueries.desktop`
		margin: 96px 0 24px 0;
		font-size: 48px;
		line-height: 1.333;
	`};
`;

const Cards = styled(Container)`
	display: flex;
	flex-flow: column;
	padding: 0 24px 0 24px;

	${({ theme }) => theme.mediaQueries.desktop`
		max-width: 100%;
		padding: 0;
	`}
`;

const SectionVideo = styled(Section)`
	@media screen and (min-width: 1140px) {
		max-width: 1140px;
		margin: 0 auto;
	}
`;

export default Home;
