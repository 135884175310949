import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

const Section = ({ children, title, leade, className }) => (
	<>
		<Container additionalClasses={className}>
			<ContainerContent>{children}</ContainerContent>
		</Container>
		<Content>
			<Title>{title}</Title>
			<Leade>{leade}</Leade>
			<Button
				href="https://privatmegleren.no/eventvisning"
				onClick={() => {
					if (window.dataLayer) {
						window.dataLayer.push({
							event: "PMCardClick",
							gaCategory: "PMCardClick",
							gaAction: "eventvisning",
							gaLabel: "Frontpage"
						});
					}
				}}
			>
				Les mer
			</Button>
		</Content>
	</>
);

const Container = styled(({ children, additionalClasses, className }) => (
	<div className={`${className} ${additionalClasses ? additionalClasses : ""}`}>{children}</div>
))`
	position: relative;
	display: block;
	height: auto;
	width: 100%;
	margin-bottom: 24px;
	background: #222;
	z-index: 1;

	&:after {
		content: "";
		display: block;
		padding-bottom: 56.25%;
	}

	${({ theme }) => theme.mediaQueries.desktop`
		&:after {
			content: "";
			display: block;
			padding-bottom: 56.25%;
		}
	`}
`;

const ContainerContent = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	flex-flow: column;
	width: 100%;
	height: 100%;
	z-index: 1;
`;

const Content = styled.div`
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	margin: 72px 0 36px 0;
	padding: 0 24px;
	text-align: center;

	${({ theme }) => theme.mediaQueries.desktop`
	margin: 120px 0 72px 0;
	`};
`;

const Title = styled.h3`
	position: relative;
	display: flex;
	text-align: center;
	margin: 0 0 48px 0;
	font-size: 36px;
	max-width: 100%;

	&:after {
		content: "";
		position: absolute;
		bottom: -18px;
		left: 50%;
		width: 72px;
		height: 2px;
		transform: translate3d(-50%, 0, 0);
		background: ${({ theme }) => theme.colors.gold.primary};
	}

	${({ theme }) => theme.mediaQueries.desktop`
		margin: 0 0 48px 0;
		font-size: 48px;
		line-height: 1.333;
	`};
`;

const Leade = styled.p`
	font-size: 18px;
	max-width: 100%;
`;

const Button = styled.a`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 42px;
	width: auto;
	padding: 0 24px;
	background: #fff;
	color: #000;
	font-weight: 400;
	text-decoration: none;
`;

Section.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	title: PropTypes.string.isRequired,
	leade: PropTypes.string.isRequired
};

export default Section;
