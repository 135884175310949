import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { fluidRange } from "polished";
import { useStore } from "@common/website/state/root_store";
import { useSpring, animated } from "react-spring/web.cjs";

import voiceBg from "@common/website/assets/images/background_voice.jpeg";
import squareBg from "@common/website/assets/images/square_lowres.png";
import mic from "@common/website/assets/images/mic.gif";

const Header = () => {
	const store = useStore();
	const [mounted, setMounted] = useState(false);
	const [runTitleAnimation, setRunTitleAnimation] = useState(false);
	const [runLeadeAnimation, setRunLeadeAnimation] = useState(false);

	const squareAnimation = useSpring({ opacity: mounted ? 1 : 0, transform: mounted ? "scale(1)" : "scale(0.9)" });
	const titleAnimation = useSpring({
		opacity: runTitleAnimation ? 1 : 0,
		transform: runTitleAnimation ? "translate3d(0, 0, 0)" : "translate3d(0, 18px, 0)"
	});
	const leadeAnimation = useSpring({
		opacity: runLeadeAnimation ? 1 : 0,
		transform: runLeadeAnimation ? "translate3d(0, 0, 0)" : "translate3d(0, 18px, 0)"
	});

	useEffect(() => {
		if (!mounted) setMounted(true);
		const titleTimer = setTimeout(() => {
			setRunTitleAnimation(true);
		}, 175);
		const leadeTimer = setTimeout(() => {
			setRunLeadeAnimation(true);
		}, 225);
		return () => {
			clearTimeout(titleTimer);
			clearTimeout(leadeTimer);
		};
	}, []);

	return (
		<Container
			onClick={() => {
				if (window.dataLayer) {
					window.dataLayer.push({
						event: "PMCardClick",
						gaCategory: "PMCardClick",
						gaAction: "selge_bolig",
						gaLabel: "Frontpage"
					});
				}
			}}
		>
			<Square style={squareAnimation}>
				<SquareContent href="https://privatmegleren.no/verdivurdering">
					<Title style={titleAnimation}>
						Skal du <br />
						selge bolig?
					</Title>
					<Leade style={leadeAnimation}>
						La en av våre
						<br /> meglere bistå deg
					</Leade>
				</SquareContent>
			</Square>
			<ContainerBg />
		</Container>
	);
};

const Container = styled.header`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 50vh;
	padding-top: 72px;
	min-height: 400px;

	${({ theme }) => theme.mediaQueries.desktop`
		min-height: 576px;
	`}
`;

const ContainerBg = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: url(${voiceBg});
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	opacity: 0.375;
	z-index: -1;
`;

const Square = styled(animated.div)`
	position: relative;
	min-width: 250px;

	height: auto;
	background: url(${squareBg});
	background-size: cover;
	background-repeat: no-repeat;

	${fluidRange(
		{
			prop: "width",
			fromSize: "250px",
			toSize: "300px"
		},
		"320px",
		"1040px"
	)};

	${({ theme }) => theme.mediaQueries.desktop`
		min-width: 300px;
		${fluidRange(
			{
				prop: "width",
				fromSize: "300px",
				toSize: "400px"
			},
			"320px",
			"1040px"
		)};
	`}

	&:after {
		content: "";
		display: block;
		padding-bottom: 100%;
	}
`;

const SquareContent = styled.a`
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;
	width: 100%;
	height: 100%;
	text-align: center;
	text-decoration: none;
	color: #fff;
`;

const Mic = styled.div`
	position: relative;
	min-width: 96px;
	margin-bottom: 12px;
	${fluidRange(
		{
			prop: "width",
			fromSize: "96px",
			toSize: "156px"
		},
		"320px",
		"1040px"
	)};
	height: auto;
	background: url(${mic});
	background-size: cover;
	background-repeat: no-repeat;

	&:after {
		content: "";
		display: block;
		padding-bottom: 100%;
	}

	${({ theme }) => theme.mediaQueries.desktop`
        margin-bottom: 24px;
    `};
`;

const Title = styled(animated.h1)`
	color: #fff;
	font-weight: 400;
	text-transform: uppercase;
	line-height: 1.2;
	max-width: 100%;

	${fluidRange(
		{
			prop: "margin-bottom",
			fromSize: "12px",
			toSize: "24px"
		},
		"320px",
		"1040px"
	)};
	${fluidRange(
		{
			prop: "font-size",
			fromSize: "30px",
			toSize: "36px"
		},
		"320px",
		"1040px"
	)};

	${({ theme }) => theme.mediaQueries.desktop`
		${fluidRange(
			{
				prop: "margin-bottom",
				fromSize: "12px",
				toSize: "24px"
			},
			"320px",
			"1040px"
		)};
		${fluidRange(
			{
				prop: "font-size",
				fromSize: "36px",
				toSize: "42px"
			},
			"320px",
			"1040px"
		)};
	`}
`;

const Leade = styled(animated.p)`
	margin: 0;
	color: #fff;
	font-weight: 400;
	text-transform: uppercase;
	line-height: 1.25;
	font-family: "Domaine Display", serif;
	max-width: 100%;
	${fluidRange(
		{
			prop: "font-size",
			fromSize: "21px",
			toSize: "24px"
		},
		"320px",
		"1040px"
	)};
`;

export default Header;
