import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import Helmet from "react-helmet-async";
import { Player, ControlBar, BigPlayButton, PosterImage } from "video-react";

import playIcon from "@common/website/assets/svg/play_button.svg";

const Video = () => {
	const videoPlayerRef = useRef();
	const VideoPlayer = useRef();
	const [mounted, setMounted] = useState(false);
	const [isSubscribed, setIsSubscribed] = useState(false);
	const [isPlaying, setIsPlaying] = useState(false);

	useEffect(() => {
		if (!mounted) {
			setMounted(true);
		}
	}, []);

	useEffect(
		() => {
			if (!isSubscribed && videoPlayerRef.current) {
				setIsSubscribed(true);
				videoPlayerRef.current.subscribeToStateChange(change => {
					if (change.paused) {
						setIsPlaying(false);
					}
				});
			}
		},
		[mounted, isPlaying]
	);

	return (
		<>
			<Overlay isPlaying={isPlaying}>
				<OverlayInner>
					<OverlayContent>
						<SmallText>Eventvisning</SmallText>
						<LargeText>
							Vi gjør visingen av <br />
							din bolig til et event
						</LargeText>
						<PlayButton
							aria-label="Spill video"
							dangerouslySetInnerHTML={{ __html: playIcon }}
							onClick={() => {
								setIsPlaying(true);
								videoPlayerRef.current.play();
							}}
						/>
					</OverlayContent>
					<OverlayBackground />
				</OverlayInner>
			</Overlay>
			{mounted ? (
				<>
					<link rel="stylesheet" href="https://cdn.reeltime.no/pm_assets/stylesheets/video-react.css" />
					<VideoContainer isPlaying={isPlaying}>
						<Player
							ref={videoPlayerRef}
							id="bgvid"
							playsInline={true}
							autoPlay={window.matchMedia("(min-width: 1025px)").matches}
							muted={!isPlaying}
							loop={false}
							poster={
								"https://cdn.reeltime.no/pm_assets/public/images/eventvisning_poster_2-1440.a7b1302dcbffd282bfa219825ea6ccde.jpg"
							}
							aspectRatio="16:9"
							fluid
						>
							<source src="https://cdn.reeltime.no/pm_assets/public/video/desktop_2.mp4" type="video/mp4" />
							<source src="https://cdn.reeltime.no/pm_assets/public/video/desktop.webm" type="video/webm" />
						</Player>
					</VideoContainer>
				</>
			) : null}
		</>
	);
};

const VideoContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: red;

	.video-react {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		max-width: 100%;
		height: 100%;
		font-family: "Proxima Nova", sans-serif;

		* {
			&:focus {
				outline: 0;
			}
		}
	}

	.video-react .video-react-big-play-button {
		display: none;
	}

	.video-react .video-react-control-bar {
		background: #000;
		opacity: ${({ isPlaying }) => (isPlaying ? 1 : 0)};
	}

	.video-react .video-react-load-progress {
		background: ${({ theme }) => theme.colors.gold.primary};
	}
`;

const Overlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 3;
	opacity: ${({ isPlaying }) => (isPlaying ? 0 : 1)};
	pointer-events: ${({ isPlaying }) => (isPlaying ? "none" : "all")};
	transition: opacity 250ms ease-in-out;
`;

const OverlayInner = styled.div`
	position: relative;
	display: flex;
	width: 100%;
	height: 100%;
	transition: all ease-in-out 250ms;
`;

const OverlayBackground = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.75);
	z-index: 2;
`;

const OverlayContent = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;
	width: 100%;
	height: 100%;
	z-index: 3;
	margin-top: 9px;
`;

const PlayButton = styled.button`
	display: flex;
	width: 72px;
	height: 72px;
	border: 0;
	background: transparent;
	cursor: pointer;
	transition: transform 90ms ease-in-out;

	&:hover {
		transform: scale(1.1);
		transform-origin: center;
	}

	&:focus {
		outline: 0;
	}

	svg {
		display: flex;
		width: 72px;
		height: 72px;

		path {
			fill: #fff;
		}
	}

	${({ theme }) => theme.mediaQueries.desktop`
		width: 96px;
		height: 96px;

		svg {
			width: 96px;
			height: 96px;
		}
	`};
`;

const SmallText = styled.p`
	text-transform: uppercase;
	margin: 0;
	font-size: 11px;

	${({ theme }) => theme.mediaQueries.desktop`
		margin: 0 0 12px 0;
		font-size: 13px;
	`};
`;

const LargeText = styled.h3`
	margin: 0 0 0px 0;
	font-size: 24px;
	line-height: 1.333;

	${({ theme }) => theme.mediaQueries.desktop`
		font-size: 36px;
		margin: 0 0 12px 0;
	`};
`;

export default Video;
