import React from "react";
import styled, { css } from "styled-components";
import { fluidRange } from "polished";
import { Link } from "react-router-dom";

// Voice
import mic from "@common/website/assets/images/mic.gif";
import voiceBg from "@common/website/assets/images/background_voice.jpeg";

// Mitt boligkjop
import mittBoligkjop from "@common/website/assets/images/card_backgrounds/mitt_boligkjop_mobile.png";
import mittBoligkjop2x from "@common/website/assets/images/card_backgrounds/mitt_boligkjop_mobile@2x.png";
import mittBoligkjop3x from "@common/website/assets/images/card_backgrounds/mitt_boligkjop_mobile@3x.png";
import mittBoligkjopDesktop from "@common/website/assets/images/card_backgrounds/mitt_boligkjop.png";
import mittBoligkjopDesktop2x from "@common/website/assets/images/card_backgrounds/mitt_boligkjop@2x.png";
import mittBoligkjopDesktop3x from "@common/website/assets/images/card_backgrounds/mitt_boligkjop@3x.png";

// Kjopsmegling logo
import kjop from "@common/website/assets/images/card_backgrounds/kjop_logo.png";
import kjop2x from "@common/website/assets/images/card_backgrounds/kjop_logo@2x.png";
import kjop3x from "@common/website/assets/images/card_backgrounds/kjop_logo@3x.png";

// Sjakk
import sjakk from "@common/website/assets/images/card_backgrounds/sjakk_mobile.png";
import sjakk2x from "@common/website/assets/images/card_backgrounds/sjakk_mobile@2x.png";
import sjakk3x from "@common/website/assets/images/card_backgrounds/sjakk_mobile@3x.png";
import sjakkDesktop from "@common/website/assets/images/card_backgrounds/sjakk.png";
import sjakkDesktop2x from "@common/website/assets/images/card_backgrounds/sjakk@2x.png";
import sjakkDesktop3x from "@common/website/assets/images/card_backgrounds/sjakk@3x.png";

// Hjort
import hjort from "@common/website/assets/images/card_backgrounds/hjort_mobile.png";
import hjort2x from "@common/website/assets/images/card_backgrounds/hjort_mobile@2x.png";
import hjort3x from "@common/website/assets/images/card_backgrounds/hjort_mobile@3x.png";
import hjortDesktop from "@common/website/assets/images/card_backgrounds/hjort.png";
import hjortDesktop2x from "@common/website/assets/images/card_backgrounds/hjort@2x.png";
import hjortDesktop3x from "@common/website/assets/images/card_backgrounds/hjort@3x.png";

// Falk
import falk from "@common/website/assets/images/card_backgrounds/falk_mobile.png";
import falk2x from "@common/website/assets/images/card_backgrounds/falk_mobile@2x.png";
import falk3x from "@common/website/assets/images/card_backgrounds/falk_mobile@3x.png";
import falkDesktop from "@common/website/assets/images/card_backgrounds/falk.png";
import falkDesktop2x from "@common/website/assets/images/card_backgrounds/falk@2x.png";
import falkDesktop3x from "@common/website/assets/images/card_backgrounds/falk@3x.png";

// Ulv
import ulv from "@common/website/assets/images/card_backgrounds/ulv_mobile.png";
import ulv2x from "@common/website/assets/images/card_backgrounds/ulv_mobile@2x.png";
import ulv3x from "@common/website/assets/images/card_backgrounds/ulv_mobile@3x.png";
import ulvDesktop from "@common/website/assets/images/card_backgrounds/ulv.png";
import ulvDesktop2x from "@common/website/assets/images/card_backgrounds/ulv@2x.png";
import ulvDesktop3x from "@common/website/assets/images/card_backgrounds/ulv@3x.png";

// Firkant
import square from "@common/website/assets/images/card_backgrounds/square_lowres.png";
import square2x from "@common/website/assets/images/card_backgrounds/square_lowres@2x.png";
import square3x from "@common/website/assets/images/card_backgrounds/square_lowres@3x.png";

const images = {
	mitt_boligsalg: {
		mobile1x: mittBoligkjop,
		mobile2x: mittBoligkjop2x,
		mobile3x: mittBoligkjop3x,
		desktop1x: mittBoligkjopDesktop,
		desktop2x: mittBoligkjopDesktop2x,
		desktop3x: mittBoligkjopDesktop3x
	},
	kjop: {
		mobile1x: sjakk,
		mobile2x: sjakk2x,
		mobile3x: sjakk3x,
		desktop1x: sjakkDesktop,
		desktop2x: sjakkDesktop2x,
		desktop3x: sjakkDesktop3x
	},
	klikk: {
		mobile1x: hjort,
		mobile2x: hjort2x,
		mobile3x: hjort3x,
		desktop1x: hjortDesktop,
		desktop2x: hjortDesktop2x,
		desktop3x: hjortDesktop3x
	},
	vare_meglere: {
		mobile1x: falk,
		mobile2x: falk2x,
		mobile3x: falk3x,
		desktop1x: falkDesktop,
		desktop2x: falkDesktop2x,
		desktop3x: falkDesktop3x
	},
	verdivurdering: {
		mobile1x: ulv,
		mobile2x: ulv2x,
		mobile3x: ulv3x,
		desktop1x: ulvDesktop,
		desktop2x: ulvDesktop2x,
		desktop3x: ulvDesktop3x
	},
	voice: {
		mobile1x: voiceBg,
		mobile2x: voiceBg,
		mobile3x: voiceBg,
		desktop1x: voiceBg,
		desktop2x: voiceBg,
		desktop3x: voiceBg
	}
};

const Card = ({ type, href, opacity, number, title, leade, special }) => {
	const linkProps =
		type === "kjop"
			? {
					to: "/kjop",
					as: Link
			  }
			: {
					href: href
			  };

	return (
		<Container
			{...linkProps}
			type={type}
			onClick={() => {
				if (window.dataLayer) {
					window.dataLayer.push({
						event: "PMCardClick",
						gaCategory: "PMCardClick",
						gaAction: type,
						gaLabel: "Frontpage"
					});
				}
			}}
			special={type === "klikk" || type === "voice" ? 1 : 0}
		>
			{type === "boligbytte" ? (
				<Content special={true} squareContent={true}>
					<div>
						<img
							alt=""
							src="https://cdn.reeltime.no/pm_assets/img/privatmeglerenboligbytte_logo.png"
							style={{ maxWidth: "300px" }}
						/>
						<p style={{ fontSize: "18px", marginBottom: "20px" }}>Sørg for å ha det bra på veien til noe bedre</p>
					</div>
				</Content>
			) : null}
			{type === "notis" ? (
				<Content>
					<NotisContent>
						<img src="https://cdn.reeltime.no/pm_assets/notis/notis.png" alt="notis" />
						<div className="notistekst">Vi gir deg muligheten til å kjøpe nybygg før resten av markedet</div>
						<SettPrisPaaButton href="https://notis.privatmegleren.no">Klikk her</SettPrisPaaButton>
					</NotisContent>
				</Content>
			) : null}
			{type === "settprispaa" ? (
				<Content special={true} squareContent={true}>
					<div>
						<SettPrisPaa>SETT PRIS PÅ</SettPrisPaa>
						<SettPrisPaaImage>
							<img src="https://cdn.reeltime.no/settprispaa/flip.gif" alt="" />
						</SettPrisPaaImage>
						<SettPrisPaaButton href="/settprispå">Søk adresse</SettPrisPaaButton>
					</div>
				</Content>
			) : (
				<Content special={special ? true : false} squareContent={type === "klikk" || type === "voice" ? true : false}>
					{number && type !== "klikk" ? <Number>{number}</Number> : null}
					{type === "klikk" || type === "voice" ? (
						<Square>
							<SquareContent>
								{number && type === "klikk" ? <Number isKlikk>{number}</Number> : null}
								{type === "voice" ? <Mic /> : null}
								{title ? (
									<Title squareTitle={true} special={type === "klikk" || type === "voice" ? true : false}>
										{title}
									</Title>
								) : null}
								{leade && leade.length ? <Leade>{leade}</Leade> : null}
							</SquareContent>
						</Square>
					) : type === "kjop" ? (
						<>
							<KjopsLogo
								src={kjop}
								srcSet={`
							${kjop} 1x, 
							${kjop2x} 2x,
							${kjop3x} 3x
						`}
							/>
							<Leade>Nå får du også bistand ved kjøp av bolig</Leade>
							<SettPrisPaaButton href="/kjop">Les mer</SettPrisPaaButton>
						</>
					) : (
						<>
							{title ? <Title special={type === "klikk" || type === "voice" ? true : false}>{title}</Title> : null}
							{leade ? <Leade>{leade}</Leade> : null}
						</>
					)}
				</Content>
			)}
			{type === "boligbytte" ? (
				<BackgroundOtherImage
					image="https://cdn.reeltime.no/pm_assets/public/images/klikk_bg_2-768.e805b2783ada6d793462993cbe29d2ef.jpg"
					opacity={1}
				/>
			) : null}
			{type === "notis" && (
				<BackgroundImageWithReadableCode url="https://cdn.reeltime.no/pm_assets/notis/notisbg-dark.jpg" />
			)}
			{type === "settprispaa" ? (
				<BackgroundImageWithReadableCodeSettpris url="https://cdn.reeltime.no/settprispaa/svigers.jpg" />
			) : images[type] ? (
				<BackgroundImage bg={images[type] || null} opacity={opacity ? opacity : null} />
			) : null}
		</Container>
	);
};

const Container = styled(({ href, to, className, children, onClick }) =>
	to ? (
		<Link to={to} className={className} onClick={onClick}>
			{children}
		</Link>
	) : (
		<a href={href} className={className} onClick={onClick}>
			{children}
		</a>
	)
)`
	position: relative;
	display: block;
	height: auto;
	width: 100%;
	background: #000;
	color: #fff;
	cursor: pointer;
	float: left;

	&:after {
		content: "";
		display: block;
		padding-bottom: 100%;
	}

	${({ theme }) => theme.mediaQueries.desktop`
		&:after {
			content: "";
			display: block;
			padding-bottom: 56.25%;
		}
	`}
`;

const NotisContent = styled.div`
	img {
		width: 150px;
		@media (min-width: 1024px) {
			width: 600px;
		}
	}
	div.notistekst {
		font-size: 18px;
		margin: 2em 0;
		width: 300px;
		@media (min-width: 1024px) {
			width: 600px;
		}
	}
`;

const SettPrisPaa = styled.div`
	font-size: 44px;
	font-family: "DomaineDisplay";
	color: #e5c88f;
	@media (min-width: 1024px) {
		font-size: 50px;
	}
`;
const SettPrisPaaImage = styled.div`
	margin-top: -30px;
	img {
		width: 220px;
	}
	@media (min-width: 1024px) {
		img {
			width: 254px;
		}
	}
`;

const SettPrisPaaButton = styled.div`
	width: 210px;
	height: 42px;
	background-color: rgb(232, 200, 147);
	color: #000;
	border: none;
	display: block;
	padding: 9px 0;
	-webkit-appeareance: none;
	border-radius: 0;
	margin: 0 auto;
	font-size: 16px;
	font-weight: 400;
	text-decoration: none;
`;

const BackgroundOtherImage = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: url(${props => props.image}) no-repeat;
	background-position: center;
	background-size: cover;
	opacity: 0.25;
`;

const BackgroundImageWithReadableCodeSettpris = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: url(${props => props.url});
	background-size: cover;
	opacity: 0.45;

	@media (min-width: 1024px) {
		background-position: 0 -376px;
	}
`;
const BackgroundImageWithReadableCode = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: url(${props => props.url});
	background-size: cover;
	opacity: 0.45;
`;

const BackgroundImage = styled(({ children, small, bg, ...rest }) => <div {...rest}>{children}</div>)`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: ${({ bg }) => `url(${bg.mobile1x})`};
	background-image: ${({ bg }) => `
		-webkit-image-set(url(${bg.mobile1x}) 1x, url(${bg.mobile2x}) 2x, url(${bg.mobile3x}) 3x)
	`};
	background-size: ${({ small }) => (small ? "contain" : "cover")};
	background-position: "center";
	background-repeat: no-repeat;
	z-index: 0;
	opacity: ${({ opacity }) => (opacity ? opacity : 0.6)};

	${({ theme, bg }) => theme.mediaQueries.desktop`
		background-image: url(${bg.desktop1x});
		
		background-image: -webkit-image-set(
			url(${bg.desktop1x}) 1.0x,
			url(${bg.desktop2x}) 2.0x,
			url(${bg.desktop3x}) 3.0x
		);
	`}
`;

const Content = styled(({ style, children, className }) => (
	<div className={className} style={style}>
		{children}
	</div>
))`
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;
	width: 100%;
	height: 100%;
	padding: 24px;
	text-align: center;
	z-index: 1;

	${({ theme, special }) =>
		special
			? css`
					h3 {
						order: 1;
						color: ${theme.colors.gold.primary};
						margin: 0;
					}
					p {
						margin: 0 0 6px 0;

						${({ theme }) => theme.mediaQueries.desktop`
							margin: 0 0 18px 0;
						`};
					}
			  `
			: ""};

	${({ theme, bg }) => theme.mediaQueries.desktop`
		position: absolute;
	`}
`;

const Number = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	min-width: 30px;
	min-height: 30px;
	margin-top: ${({ isKlikk }) => (isKlikk ? "-15px" : "0")};
	margin-bottom: ${({ isKlikk }) => (isKlikk ? "15px" : "15px")};
	border-radius: 50%;
	text-align: center;
	background: #fff;
	color: #000;
	font-family: "Domaine Display", serif;
	font-size: 20px;
	line-height: 1;

	${({ theme }) => theme.mediaQueries.desktop`
		margin-top: ${({ isKlikk }) => (isKlikk ? "-24px" : "0")};
		margin-bottom: ${({ isKlikk }) => (isKlikk ? "24px" : "48px")};
		width: 48px;
		height: 48px;
		min-width: 48px;
		min-height: 48px;
		font-size: 30px;
	`};
`;

const Title = styled(({ children, className }) => <h3 className={className}>{children}</h3>)`
	margin: 0 0 12px 0;
	font-size: ${({ special }) => (special ? "21px" : "36px")};
	max-width: 100%;
	line-height: 1.2;
	padding: 0;

	${({ theme }) => theme.mediaQueries.tablet`
		padding: 0 12px;
	`}

	${({ theme }) => theme.mediaQueries.desktop`
		font-size: 42px;
		margin: 0 0 36px 0;
		padding: 0 12px;
	`}

	${({ squareTitle }) =>
		squareTitle
			? css`
					${fluidRange(
						{
							prop: "font-size",
							fromSize: "21px",
							toSize: "36px"
						},
						"768px",
						"1440px"
					)};
			  `
			: ""}
`;

const Leade = styled.p`
	font-size: 18px;
	max-width: 100%;
`;

const KjopsLogo = styled.img`
	position: relative;
	width: 60%;
	height: auto;
	margin-bottom: 12px;

	@media screen and (min-width: 768px) {
		max-width: 576px;
		width: auto;
	}
`;

const Square = styled.div`
	position: relative;
	min-width: 250px;
	max-width: 456px;
	width: 75%;
	height: auto;
	background-image: url(${square});
	background-image: -webkit-image-set(url(${square}) 1x, url(${square2x}) 2x, url(${square3x}) 3x);
	background-size: cover;
	background-repeat: no-repeat;

	&:after {
		content: "";
		display: block;
		padding-bottom: 100%;
	}

	${fluidRange(
		{
			prop: "width",
			fromSize: "250px",
			toSize: "550px"
		},
		"768px",
		"1440px"
	)};
`;

const SquareContent = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;
	width: 100%;
	height: 100%;
	padding: 24px;
	text-align: center;

	p {
		margin-bottom: 0;
		font-size: 18px;
	}
`;

const Mic = styled.div`
	position: relative;
	min-width: 48px;
	max-width: 60px;
	margin-bottom: 18px;
	width: 75%;
	height: auto;
	background-image: url(${mic});
	background-size: cover;
	background-repeat: no-repeat;

	&:after {
		content: "";
		display: block;
		padding-bottom: 100%;
	}

	${({ theme }) => theme.mediaQueries.desktop`
		min-width: 96px;
		max-width: 125px;
		${fluidRange(
			{
				prop: "width",
				fromSize: "72px",
				toSize: "125px"
			},
			"320px",
			"1040px"
		)};
	`};
`;

export default Card;
